import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import axios from 'axios';
import Layout from '../../../components/layout';
import Seo from '../../../components/seo';
import ListBrand from '../../../components/list-brand';
import ListLink from '../../../components/list-link';
import HtmlEscape from '../../../components/EscapeHtml';
import Image from '../../../components/Image';

const KnowledgePage = (props) => {
  const { location } = props;
  const [pageId, setPageId] = useState('');
  const [content, setContent] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [prevPage, setPrevPage] = useState('');
  // Ajax設定
  const configJson = {
    method: 'get',
    url: `/update/knowledge/${pageId}/setting.json`,
    headers: {
      Accept: 'application/json',
    },
    data: '',
  };
  const configText = {
    method: 'get',
    url: `/update/knowledge/${pageId}/index.html`,
    headers: {
      Accept: 'text/html',
    },
    data: '',
  };
  // Ajax実行
  const dataFetch = async () => {
    axios(configJson)
    .then((response) => {
      setPageTitle(response.data.title);
      setNextPage(response.data.next);
      setPrevPage(response.data.prev);
    })
    .catch(() => {
      // console.log(error);
    });
    axios(configText)
    .then((response) => {
      setContent(response.data);
    })
    .catch(() => {
      // console.log(error);
    });
  };
  // Event
  useEffect(() => {
    setPageId(location.search.split('?id=')[1]);
    if (pageId) {
      dataFetch();
    }
    if (typeof pageId === 'undefined') {
      navigate('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, location.search.split('?id=')[1]]);
  return (
      <Layout page="knowledge detail">
        <Seo title="KNOWLEDGE"/>
        <section className="page-head">
          <ListBrand/>
          <ListLink/>
          <h2 className="main-title"><span>KNOWLEDGE</span></h2>
        </section>
        <section className="page-content flex-layout">
          <div className="main-content">
            {HtmlEscape(content)}
            <div className="under-pager">
              {prevPage === ''
                  ? <p className="no-link prev">←</p>
                  : <Link to={`/beginner/knowledge/detail/?id=${prevPage}`} className="prev">←</Link>
              }
              <Link to="/beginner/knowledge" className="list-ttl">記事一覧</Link>
              {nextPage === ''
                  ? <p className="no-link next">→</p>
                  : <Link to={`/beginner/knowledge/detail/?id=${nextPage}`} className="next">→</Link>
              }
            </div>
          </div>
          <div className="side-list side-pager">
            <ul>
              <li>
                <Link to="/beginner/knowledge">一覧へ戻る</Link>
              </li>
              <li>
                {nextPage === ''
                    ? <p className="no-link next">次の記事</p>
                    : <Link to={`/beginner/knowledge/detail/?id=${nextPage}`} className="next">次の記事</Link>
                }
              </li>
              <li>
                {prevPage === ''
                    ? <p className="no-link prev">前の記事</p>
                    : <Link to={`/beginner/knowledge/detail/?id=${prevPage}`} className="prev">前の記事</Link>
                }
              </li>
            </ul>
          </div>
        </section>
        <section className="bread">
          <ul>
            <li>
              <Link to="/">
                <Image filename="ico/home.png" />
              </Link>
            </li>
            <li>
              <Link to="/beginner/knowledge/">プロが贈る薪ストーブの知恵袋</Link>
            </li>
            <li>{pageTitle}</li>
          </ul>
        </section>
      </Layout>
  );
};

export default KnowledgePage;
